import React, { useRef, useState } from 'react';

import { List, InputItem, Button, WhiteSpace } from 'antd-mobile';

const InputForm = ({ ticket, extensions, handleTicketParamDataChange, intl, activeStep }) => {
  
  const { [extensions.inputParamItem]:defaultInputValue } = ticket.param_data;
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState(defaultInputValue); 

  const handleSubmit = () => {
    handleTicketParamDataChange({ [extensions.inputParamItem]:inputValue });
  };

  return (
    <>
     <InputItem
        ref={inputRef}
        placeholder={ intl.formatMessage({ id: 'p/flow/NodeDetailForm.input' })  }
        value={inputValue}
        disabled={!activeStep}
        onChange={(v) => {
          setInputValue(v);
        }}
      />
      <Button
        type="primary"
        size="small"
        onClick={handleSubmit}
        disabled={!activeStep || ticket.status !== 'started' || ticket.delete_flag}
      >{ intl.formatMessage({ id: 'form.save' })  }</Button>
    </>
  );
};

export default InputForm;
