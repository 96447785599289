export default {
  'prev': '上一页',
  'next': '下一页',
  'profile.info': '个人信息',
  'profile.name': '姓名',
  'profile.email': '电子邮件',
  'profile.mobileno': '手机号码',
  'profile.created': '创建日期',
  'profile.project': '项目',
  'profile.my.project': '我的项目',
  'profile.participated.project': '关联项目',
  'profile.public.project': '公开项目',
  'ticket.list': '工单一览',
  'p/tkt/CommentDetail.saved': '您已保存评论。',
  'p/tkt/Detail.back': '返回',
  'p/tkt/Detail.cancelled': '已取消',
  'p/tkt/Detail.createat': '创作时间',
  'p/tkt/Detail.creator': '作者',
  'p/tkt/Detail.end.date': '时间结束',
  'p/tkt/Detail.flow': '工作流',
  'p/tkt/Detail.flow.chart': '工作流图',
  'p/tkt/Detail.process.time': '目标时间',
  'p/tkt/Detail.project': '项目',
  'p/tkt/Detail.reopen': '重新开始',
  'p/tkt/Detail.reopen.succeed': '它已恢复。',
  'p/tkt/Detail.restore': '再开',
  'p/tkt/Detail.restored': '本票已经变成开启状态。',
  'p/tkt/Detail.same.flow': '类似工作票',
  'p/tkt/Detail.status': '状态',
  'p/tkt/Detail.suspend': '暂停',
  'p/tkt/Detail.suspend.succeed': '暂停了。',
  'p/tkt/Detail.test.only': '用于测试工作流程，不是正式票。',
  'p/tkt/Detail.ticket.detail': '步骤详细',
  'p/tkt/Detail.time.passed': '经过的时间',
  'p/tkt/Detail.viewlog': '查看日志',
  'p/tkt/Detail.warning': '有警告',
  'p/tkt/Detail.work.time': '工作时间',
  'p/tkt/StepDetail.end.time': '完成时间',
  'p/tkt/StepDetail.mailer': '邮递员',
  'p/tkt/StepDetail.memo': '备注',
  'p/tkt/StepDetail.start.time': '开始时间',
  'p/tkt/StepDetail.suspend': '暂停',
  'p/tkt/StepDetail.time.limited': '目标时间',
  'p/tkt/StepDetail.time.used': '经过的时间',
  'p/tkt/StepDetail.work.action.label': '动作',
  'p/tkt/StepDetail.work.content': '工作内容',
  'p/tkt/c/CommentDetail.add.comment': '添加备注',
  'p/tkt/c/CommentDetail.worklog': '备注',
  'p/tkt/c/Deadline.process.timeout': '超过目标时间',
  'p/tkt/c/StepDetail.close': '关闭',
  'p/tkt/c/StepDetail.executing': '实施中。。。',
  'p/tkt/c/StepDetail.next': '下一步',
  'p/tkt/c/StepDetail.node.resume': '从这里重新开始',
  'p/tkt/c/StepDetail.operator': '操作者:',
  'p/tkt/c/StepDetail.send': '送信',
  'p/tkt/c/StepDetail.unexpeted.error': '意外的错误',
  'p/tkt/c/TicketHeader.none': '没有',
  'p/user/login/index.countdown': '{count}秒',
  'p/user/login/index.get.code': '获取代码',
  'p/user/login/index.login': '登录',
  'p/usr/Detail.activity': '活动',
  'p/usr/Detail.edit': '编辑...',
  'p/usr/Detail.flow': '工作流',
  'p/usr/Detail.overview': '总览',
  'p/usr/Detail.project': '项目',
  'p/usr/Detail.relatived.group': '群组会员',
  'p/usr/Detail.snippet': '备注',
  'p/usr/Detail.ticket': '工单',
  'p/usr/List.new': '新建',
  'p/usr/c/Overview/index.acl_role': '角色',
  'p/usr/c/Overview/index.createdat': '创建日期',
  'p/usr/c/Overview/index.fullname': '名称',
  'p/usr/c/Overview/index.id': 'ID',
  'p/usr/c/Overview/index.mail': '电子邮件',
  'p/usr/c/Overview/index.memo': '备注',
  'p/usr/c/Overview/index.mobileno': '手机号码',
  'p/tkt/Detail.auto.fixed': '自动解析',
  'p/tkt/Detail.cancel': '取消',
  'p/tkt/Detail.etc.reason': '其他原因',
  'p/tkt/Detail.wrong.ticket': '工单有误',
  'initial': '处理中',
  'suspend': '临时停止',
  'started': '处理中',
  'closed': '正常结束',
  'canceled': '已取消',
  'p/flow/c/Mailer.attachments': '附件',
  'p/flow/c/Mailer.bcc': '密送',
  'p/flow/c/Mailer.body': '内容',
  'p/flow/c/Mailer.cc': '抄送',
  'p/flow/c/Mailer.subject': '主题',
  'p/flow/c/Mailer.to': '收件人',
  'p/flow/NodeDetailForm.input': '输入项目',
  'form.save': '保存',
};
