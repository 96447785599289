export default {
  'prev': 'Prev',
  'next': 'Next',
  'profile.info': 'Profile Info',
  'profile.name': 'User Name',
  'profile.email': 'Email',
  'profile.mobileno': 'Phone Number',
  'profile.created': 'Created Date',
  'profile.project': 'Project',
  'profile.my.project': 'My Project',
  'profile.participated.project': 'Participated Project',
  'profile.public.project': 'Public Project',
  'ticket.list': 'Ticket List',
  'p/tkt/CommentDetail.saved': 'Comments saved.',
  'p/tkt/Detail.back': 'Back',
  'p/tkt/Detail.cancelled': 'Cancel.',
  'p/tkt/Detail.createat': 'Created time',
  'p/tkt/Detail.creator': 'Creator',
  'p/tkt/Detail.end.date': 'End time',
  'p/tkt/Detail.flow': 'Flow',
  'p/tkt/Detail.flow.chart': 'Flow Diagram',
  'p/tkt/Detail.process.time': 'Limit',
  'p/tkt/Detail.project': 'project',
  'p/tkt/Detail.reopen': 'Resume',
  'p/tkt/Detail.reopen.succeed': 'Resumed.',
  'p/tkt/Detail.restore': 'Resurrection',
  'p/tkt/Detail.restored': 'Resurrected.',
  'p/tkt/Detail.same.flow': 'Similar ticket',
  'p/tkt/Detail.status': 'Status',
  'p/tkt/Detail.suspend': 'Pause',
  'p/tkt/Detail.suspend.succeed': 'Suspended.',
  'p/tkt/Detail.test.only': 'For confirmation of flow. It is not a production ticket.',
  'p/tkt/Detail.ticket.detail': 'Work Details',
  'p/tkt/Detail.time.passed': 'Elapsed time',
  'p/tkt/Detail.viewlog': 'View Log',
  'p/tkt/Detail.warning': 'Warning',
  'p/tkt/Detail.work.time': 'Working time',
  'p/tkt/StepDetail.end.time': 'Completion time',
  'p/tkt/StepDetail.mailer': 'Mailer',
  'p/tkt/StepDetail.memo': 'Memo',
  'p/tkt/StepDetail.start.time': 'Start time',
  'p/tkt/StepDetail.suspend': 'Pause',
  'p/tkt/StepDetail.time.limited': 'Limit',
  'p/tkt/StepDetail.time.used': 'Elapsed time',
  'p/tkt/StepDetail.work.action.label': 'Action',
  'p/tkt/StepDetail.work.content': 'Content',
  'p/tkt/c/CommentDetail.add.comment': 'Add work note',
  'p/tkt/c/CommentDetail.worklog': 'Comment',
  'p/tkt/c/Deadline.process.timeout': 'Exceeding the target time',
  'p/tkt/c/StepDetail.close': 'Close',
  'p/tkt/c/StepDetail.executing': 'Running. .. ..',
  'p/tkt/c/StepDetail.next': 'Next',
  'p/tkt/c/StepDetail.node.resume': 'Restart here',
  'p/tkt/c/StepDetail.operator': 'Operator:',
  'p/tkt/c/StepDetail.send': 'Send',
  'p/tkt/c/StepDetail.unexpeted.error': 'Unexpected error',
  'p/tkt/c/TicketHeader.none': 'None',
  'p/user/login/index.countdown': '{count} seconds',
  'p/user/login/index.get.code': 'Get the code',
  'p/user/login/index.login': 'Login',
  'p/usr/Detail.activity': 'Activity',
  'p/usr/Detail.edit': 'Edit...',
  'p/usr/Detail.flow': 'Flow',
  'p/usr/Detail.overview': 'Overview',
  'p/usr/Detail.project': 'Project',
  'p/usr/Detail.relatived.group': 'Group',
  'p/usr/Detail.snippet': 'Snippet',
  'p/usr/Detail.ticket': 'Ticket',
  'p/usr/List.new': 'New',
  'p/usr/c/Overview/index.acl_role': 'role',
  'p/usr/c/Overview/index.createdat': 'Created At',
  'p/usr/c/Overview/index.fullname': 'name',
  'p/usr/c/Overview/index.id': 'ID',
  'p/usr/c/Overview/index.mail': 'mail',
  'p/usr/c/Overview/index.memo': 'Memo',
  'p/usr/c/Overview/index.mobileno': 'Mobile number',
  'p/tkt/Detail.auto.fixed': 'Automatic resolution',
  'p/tkt/Detail.cancel': 'Cancel',
  'p/tkt/Detail.etc.reason': 'Other causes',
  'p/tkt/Detail.wrong.ticket': 'Voting error',
  'initial': 'Initial',
  'suspend': 'Suspend',
  'started': 'Started',
  'closed': 'Closed',
  'canceled': 'Canceled',
  'p/flow/c/Mailer.attachments': 'Attachments',
  'p/flow/c/Mailer.bcc': 'Bcc',
  'p/flow/c/Mailer.body': 'Body',
  'p/flow/c/Mailer.cc': 'Cc',
  'p/flow/c/Mailer.subject': 'Subject',
  'p/flow/c/Mailer.to': 'To',
  'p/flow/NodeDetailForm.input': 'Input',
  'form.save': 'Save',
};
