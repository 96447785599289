export const REMOTE_URL =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : '';
export const FILE_URL =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : '';

export const fileUrl = f => (f.startsWith('http') ? f : `${FILE_URL}/${f}`);

// systemuser
export const SYSTEMUSER_TOUCH_REQUEST = 'SYSTEMUSER_TOUCH_REQUEST';
export const SYSTEMUSER_TOUCH_SUCCESS = 'SYSTEMUSER_TOUCH_SUCCESS';
export const SYSTEMUSER_TOUCH_FAILURE = 'SYSTEMUSER_TOUCH_FAILURE';
// project
export const PROJECTS_REQUEST = 'PROJECTS_REQUEST';
export const PROJECTS_SUCCESS = 'PROJECTS_SUCCESS';
export const PROJECTS_FAILURE = 'PROJECTS_FAILURE';
// ticket
export const TICKETS_REQUEST = 'TICKETS_REQUEST';
export const TICKETS_SUCCESS = 'TICKETS_SUCCESS';
export const TICKETS_FAILURE = 'TICKETS_FAILURE';

export const TICKET_REQUEST = 'TICKET_REQUEST';
export const TICKET_SUCCESS = 'TICKET_SUCCESS';
export const TICKET_FAILURE = 'TICKET_FAILURE';

export const TICKET_VM_REQUEST = 'TICKET_VM_REQUEST';
export const TICKET_VM_SUCCESS = 'TICKET_VM_SUCCESS';
export const TICKET_VM_FAILURE = 'TICKET_VM_FAILURE';