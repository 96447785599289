import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { EventEmitter } from 'fbemitter';

import { IntlProvider } from 'react-intl';
import en_US from './locales/en-US';
import ja_JP from './locales/ja-JP';
import zh_CN from './locales/zh-CN';

import App from './App';

import './index.css';
import registerServiceWorker from './registerServiceWorker';


import configureStore from './store/configureStore';
import 'moment/locale/ja';

const store = configureStore();

let messages = {}
messages['en'] = en_US;
messages['ja'] = ja_JP;
messages['zh'] = zh_CN;


const emitter = new EventEmitter();
window.emitter = emitter;

render(
  <IntlProvider locale={navigator.language} messages={messages[navigator.language ? navigator.language.substr(0, 2) : 'ja'] || messages['en']}>
    <Provider store={store}>
      <App />
    </Provider>
  </IntlProvider >
  ,
  document.getElementById('root')
);
registerServiceWorker();
