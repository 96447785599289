import React, { useRef, useEffect } from 'react';

/**
 * dangerouslySetInnerHTMLの対応策
 */
const InnerHTML = (props) => {
  const ref = useRef();
  useEffect(() => {
    ref.current.innerHTML = props.children;
  }, [props]);

  return <span ref={ref} {...props} />;
};

export default InnerHTML;