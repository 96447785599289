import React, { useRef, useEffect, useState } from 'react';
import template from 'es6-template-strings';
import InnerHTML from '../components/InnerHTML';
import Mailer from '../components/Mailer';
import InputForm from '../components/InputForm';
import {
  List,
  Toast,
  WingBlank,
  InputItem,
  Button,
  WhiteSpace,
  ActivityIndicator,
  Accordion,
  Card,
  NavBar,
  Icon,
  Steps,
  Tag,
  Radio,
} from 'antd-mobile';


const RadioItem = Radio.RadioItem;

const nextStepLabel = (node, intl) => {
    let title = intl.formatMessage({ id: 'p/tkt/c/StepDetail.next' });
  
    if (node.shape === 'flow-circle') {
      if (node.inEdges) {
        title = intl.formatMessage({ id: 'p/tkt/c/StepDetail.close' });
      }
    }
    return title;
};

const SendMail = ({ ticket, projectPerms, mail, loading, vmMsg, onSend, onMailChanged, intl, activeStep }) => {
    return (
        <>
        <Mailer mail={mail} onChanged={onMailChanged} activeStep={activeStep} intl={intl} />
        {projectPerms.some((r) => ['project.*', 'flow.*', 'ticket.*'].includes(r)) && (
            <div>
                <WhiteSpace size="sm" />
                <Button
                    size="small"
                    type="primary"
                    // icon={<MailOutlined />}
                    loading={loading}
                    onClick={onSend}
                    disabled={!activeStep || ticket.status !== 'started' || ticket.delete_flag}
                >
                    { intl.formatMessage({ id: 'p/tkt/c/StepDetail.send' }) }
                </Button>
                <InnerHTML>{vmMsg}</InnerHTML>
            </div>
        )}
        </>
    );
};

// compile 'ticket param_data' to 'mail template'
const compiledMail = (mail = {}, params = {}) => {
    // eslint-disable-next-line
    return Object.keys(mail).reduce((r, k) => ((r[k] = template(mail[k], { params })), r), {});
};

const RenderRhombus = ({ ticket, extensions, activeStep, option, onChange, node }) => {
    const defaultValue = option || ticket.param_data[extensions.refParam];
    return (
      <Card>
            <Card.Body>
                <List>
                {node.outEdges
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((e) => (
                    <RadioItem key={e.id} checked={defaultValue===e.label} onChange={(v)=>onChange(e.label)} disabled={!activeStep} >
                        <span>{e.label}</span>
                        </RadioItem>
                    ))}
                </List>
            </Card.Body>
      </Card>
    );
  };

const StepDetail = ({
    dispatch,
    ticket,
    projectPerms,
    step,
    node,
    test_flag,
    activeStep = false,
    loading,
    ticketAction,
    executeVm,
    intl,
  }) => {
 
  const [option, setOption] = useState(step?.form_data?.option);
  const [vmExecuting, setVmExecuting] = useState(false);
  const [vmMsg, setVmMsg] = useState('');
  const [mail, setMail] = useState();

  const nodeParamData = node?.extensions?.param_data || {};
  const nodeParamDataWithData = Object.keys(nodeParamData).reduce((r, v) => {
    // eslint-disable-next-line
    r[v] = ticket.param_data[v] || nodeParamData[v];
    return r;
  }, {});
  const [paramData, setParamData] = useState(nodeParamDataWithData);

  const getState = () => ({
    option,
    vmExecuting,
    vmMsg,
    mail,
    param_data: paramData,
    ...step.form_data,
  });

  const goNextStep = (form_data) => {
    const payload = {
      node_id: node.id,
      form_data,
      updatedAt: ticket.updatedAt,
    };

    sessionStorage.removeItem(`resumeNode_${ticket.id}`);

    console.log("????: ", ticketAction);
    ticketAction({
          type: 'ticket/action',
          action: 'next',
          project: ticket.workProject.id,
          id: ticket.id,
          test_flag,
          payload,
          msg: "saved",
          callback: () => {
            window.scrollBy(0, 500);
          },
        });
  };

//   const sendNoti = () => {
//     dispatch({
//       type: 'ticket/action',
//       action: 'sendNoti',
//       project: ticket.workProject.id,
//       id: ticket.id,
//       test_flag,
//       msg: intl.formatMessage({
//         id: 'saved',
//       }),
//       callback: () => {
//         window.scrollBy(0, 500);
//       },
//     });
//   };

  const execAction = (project, ticketId, node_id, data) => {
    setVmExecuting(true);
    setVmMsg("実行中。。。");

    // TODO: then?callback?

    // executeVm({ project, ticketId, node_id, data, test_flag })
    //   .then(({ msg }) => {
    //     setVmExecuting(false);
    //     setVmMsg(`${msg}メール送信しました。`);

    //     ticketAction({
    //       type: 'ticket/detail',
    //       project: ticket.workProject.id,
    //       id: ticket.id,
    //       test_flag,
    //     });
    //   })
    //   .catch(() => {
    //     setVmExecuting(false);
    //     setVmMsg("予想外エラー");
    //   });
  };

  const onTicketParamDataChange = (params) => {
    ticketAction({
      type: 'ticket/action',
      action: 'saveParams',
      project: ticket.workProject.id,
      id: ticket.id,
      test_flag,
      payload: {
        ...params,
        updatedAt: ticket.updatedAt,
      },
      msg: "コメントを保存しました。",
    });
  };

  const { extensions = {} } = node;
  const { operator, permissions: nodePrems, form_data } = step;
  const { action_result: actionResult = {} } = ticket;

  const properities = Object.keys(nodeParamDataWithData).map((key) => ({
        id: key,
        key,
        value: nodeParamDataWithData[key],
    }));

  return (
    <WingBlank size="sm">
    <WhiteSpace size="sm" />
    {extensions.content && (
        <Card>
            <Card.Header
                style={{fontSize: 14}}
                title={ intl.formatMessage({ id: 'p/tkt/StepDetail.work.content' }) }
            />
            <Card.Body>
            <InnerHTML className="workConent">
                {template(extensions.content, {
                params: ticket?.param_data,
                })}
            </InnerHTML>
            </Card.Body>
        </Card>
        )}
    {extensions.action && projectPerms.some((r) => ['project.*', 'flow.*', 'ticket.*'].includes(r)) && (
    <div>
        <WhiteSpace size="sm" />
        <Card>
            <Card.Body>
                <Button
                    type="primary"
                    size="small"
                    loading={vmExecuting}
                    onClick={() => execAction(ticket.workProject.id, ticket.id, node.id)}
                    disabled={ticket.status !== 'started' || ticket.delete_flag}
                >
                    {extensions.actionLabel || intl.formatMessage({ id: 'p/tkt/StepDetail.work.action.label' })}
                </Button>
                <WhiteSpace size="sm" />
                <div>
                    <InnerHTML>{vmMsg || actionResult[node.id]}</InnerHTML>
                </div>
            </Card.Body>
        </Card>
    </div>
    )}
    {extensions.param_data && projectPerms.some((r) => ['project.*', 'flow.*', 'ticket.*'].includes(r)) && (
    // <PropertiesPanel
    //     paramData={nodeParamDataWithData}
    //     onChange={({ param_data }) => setParamData(param_data)}
    //     ability={['U']}
    // />
    <div>
    <WhiteSpace size="sm" />
        <Card>
            <Card.Body>
                <List>
                { properities.map((p) => (
                    <List.Item key={p.id} extra={p.value}>{p.key}</List.Item>
                )) }
                </List>
            </Card.Body>
        </Card>
    </div>
    )}

    {node.shape === 'flow-rect-mailer' && (
        <>
            <WhiteSpace size="sm" />
            <Card>
                <Card.Header
                    title={ intl.formatMessage({ id: 'p/tkt/StepDetail.mailer' }) }
                    style={{fontSize: 14}}
                />
                <Card.Body>
                <SendMail
                    ticket={ticket}
                    intl={intl}
                    projectPerms={projectPerms}
                    mail={form_data?.mail || (mail || compiledMail(extensions.mail, ticket.param_data)) || {}}
                    loading={vmExecuting}
                    vmMsg={vmMsg}
                    onSend={() =>
                        execAction(
                            ticket.id,
                            node.id,
                            mail || compiledMail(extensions.mail, ticket.param_data) || {},)
                    }
                    activeStep={activeStep}
                    onMailChanged={setMail}
                />
                </Card.Body>
            </Card>
        </>
    )}

    {node.extensions && node.shape === 'flow-rect-input' && (
        <>
            <WhiteSpace size="sm" />
            <Card>
                <Card.Body>
                    <InputForm
                        ticket={ticket}
                        extensions={node.extensions}
                        handleTicketParamDataChange={onTicketParamDataChange}
                        intl={intl}
                        activeStep={activeStep}
                    />
                </Card.Body>
            </Card>
        </>
    )}

    {node.outEdges &&
    node.outEdges.length > 1 &&
    node.shape === 'flow-rhombus' && ( // flow-rhombus
        <>
        <WhiteSpace size="sm" />
        <RenderRhombus
            ticket={ticket}
            extensions={extensions}
            activeStep={activeStep}
            option={option}
            node={node}
            onChange={(e) => setOption(e)}
        />
        </>
    )}

    {activeStep ? (
        !ticket.suspend &&
        projectPerms.some((r) => ['project.*', 'flow.*', 'ticket.*'].includes(r)) &&
        (!nodePrems || nodePrems.some((r) => ['APPROVE'].includes(r)) ? (
        <>
            <WhiteSpace size="sm" />
            <Button
                loading={loading}
                onClick={() => goNextStep(getState())}
                type="primary"
                disabled={
                (node.outEdges && node.outEdges.length > 1 && !option) ||
                ticket.status !== 'started' ||
                ticket.delete_flag ||
                vmExecuting
                }
            >
                {nextStepLabel(node, intl)}
            </Button>
        </>
        ) : (
        <Button
            // onClick={() => sendNoti()}
            disabled={ticket.status !== 'started' || ticket.delete_flag}
        >
            {`${extensions?.authRequired?.systemUsers || ''}${
            extensions?.authRequired?.groups || ''
            }宛てに送信`}
        </Button>
        ))
    ) : (
        <div>
            <WhiteSpace size="sm" />
        { intl.formatMessage({ id: 'p/tkt/c/StepDetail.operator' }) }: {operator.fullname}
        </div>
    )}
    <WhiteSpace size="sm" />
    </WingBlank>
  );        

};

export default StepDetail;