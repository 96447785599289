import { List, Pagination, NavBar, Icon, } from 'antd-mobile';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  withRouter,
} from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import {
  getTickets,
} from '../actions/ticket';

import NoData from '../components/NoData';

const { Item } = List;

class Tickets extends Component {
  componentDidMount() {
    const {
      match: {
        params: { projectid }
      },
    } = this.props;

    this.props.getTickets(projectid, {workProject: projectid});
  }

  onChangePage = page => {
    const {
      match: {
        params: { projectid }
      },
    } = this.props;
    const params = {
      current: page,
    };
    this.props.getTickets(projectid, params);
  }

  render() {
    const {
      tickets,
      pagination,
    } = this.props;
    return (
      <div>
        <NavBar
          mode="dark"
          icon={<Icon type="left" />}
          onLeftClick={() => { window.history.back() }}
        ><FormattedMessage id="ticket.list" /></NavBar>
        <List
          renderHeader={() => <FormattedMessage id="ticket.list" />}
        >
          {tickets.length > 0 ? tickets.map(item => (
            <Item
              key={item.id}
              onClick={() => {
                this.props.history.push(`/ticket/${item.workProject.id}/${item.id}`);
              }}
              arrow="horizontal"
            >
              {item.ticket_name}
            </Item>
          )) : (
              <NoData />
            )
          }
        </List>
        {
          tickets.length > 0 && (
            <Pagination total={Math.ceil(pagination.total / pagination.pageSize)} current={pagination.current} locale={{ prevText: <FormattedMessage id="prev" />, nextText: <FormattedMessage id="next" /> }} onChange={this.onChangePage} />
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tickets: state.tickets.list,
  pagination: state.tickets.pagination,
});
const mapDispatchToProps = dispatch => ({
  getTickets: (projectid, params) => dispatch(getTickets(projectid, params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Tickets));
