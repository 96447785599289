import * as ACTION from '../constants';

const INITIAL_STATE = {
  error: null,
  loading: false,
  data: {},
  permissions: [],
};

export default function (state = INITIAL_STATE, action) {

  switch (action.type) {
    case ACTION.TICKET_REQUEST:
    // case ACTION.TICKET_CONFIRM_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ACTION.TICKET_SUCCESS:
    // case ACTION.TICKET_CONFIRM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data || {},
        permissions: action.permissions || [],
        error: null,
      };
    case ACTION.TICKET_FAILURE:
    // case ACTION.TICKET_CONFIRM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
