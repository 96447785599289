import moment from 'moment';

export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}

export function getTimeDistance(type) {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  if (type === 'today') {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return [moment(now), moment(now.getTime() + (oneDay - 1000))];
  }

  if (type === 'week') {
    let day = now.getDay();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }

    const beginTime = now.getTime() - day * oneDay;

    return [moment(beginTime), moment(beginTime + (7 * oneDay - 1000))];
  }

  if (type === 'month') {
    const year = now.getFullYear();
    const month = now.getMonth();
    const nextDate = moment(now).add(1, 'months');
    const nextYear = nextDate.year();
    const nextMonth = nextDate.month();

    return [
      moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
      moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000),
    ];
  }

  const year = now.getFullYear();
  return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
}

const UNITS = [
  {
    u: 's',
    label: '秒',
    q: 1,
  },
  {
    u: 'm',
    label: '分',
    q: 60,
  },
  {
    u: 'h',
    label: '時間',
    q: 60 * 60,
  },
  {
    u: 'd',
    label: '日',
    q: 24 * 60 * 60,
  },
  {
    u: 'w',
    label: '週',
    q: 7 * 24 * 60 * 60,
  },
];
const toSecond = (str) => {
  const [whole, first, _next] = /(\d+)(.*)/.exec(str); // eslint-disable-line
  let next = _next ? _next : 's'; // eslint-disable-line
  return parseInt(first, 10) * UNITS.find((v) => v.u === next).q;
};
const formatSecond = (second, units) => {
  const u = units[units.length - 1];
  const result = parseInt(second / u.q, 10);
  const m = parseInt(second % u.q, 10);
  const curr = result > 0 ? `${result}${u.label}` : '';
  const mod = m > 0 ? formatSecond(m, units.slice(0, units.length - 1)) : '';
  return `${curr} ${mod}`.trim();
};
export const formatTimePeriod = (str) => {
  try {
    const second = toSecond(str);
    const fmt = formatSecond(second, UNITS);
    return fmt.split(/ /, 2).join(' ') || '0秒';
  } catch (err) {
    return '0秒';
  }
};
export const getValue = (obj) =>
  Object.keys(obj)
    .map((key) => obj[key])
    .join(',');

export const isEmptyObject = (obj) =>
  [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length;
