import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import {
  List,
  WhiteSpace,
} from 'antd-mobile';
import { FormattedMessage } from 'react-intl';

const { Item } = List;

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: this.props.profile,
    };
  }

  render() {
    const { profile } = this.state;
    return (
      <div>
        <List renderHeader={() => <FormattedMessage id="profile.info" />}>
          {
            !profile.id || (
              <Item extra={profile.userid}>ID</Item>
            )
          }
          {
            !profile.fullname || (
              <Item extra={profile.fullname}><FormattedMessage id="profile.name" /></Item>
            )
          }
          {
            !profile.email || (
              <Item extra={profile.email}><FormattedMessage id="profile.email" /></Item>
            )
          }
          {
            !profile.mobileno || (
              <Item extra={profile.mobileno}><FormattedMessage id="profile.mobileno" /></Item>
            )
          }
          {
            !profile.createdAt || (
              <Item extra={moment(profile.createdAt).format('YYYY-MM-DD HH:mm')}><FormattedMessage id="profile.created" /></Item>
            )
          }
        </List>
        <WhiteSpace />
        <List renderHeader={() => <FormattedMessage id="profile.project" />}>
          <Item
            onClick={() => {
              this.props.history.push('/projects/my');
            }}
            arrow="horizontal"
          >
            <FormattedMessage id="profile.my.project" />
          </Item>
          <Item
            onClick={() => {
              this.props.history.push('/projects/participated');
            }}
            arrow="horizontal"
          >
            <FormattedMessage id="profile.participated.project" />
          </Item>
          <Item
            onClick={() => {
              this.props.history.push('/projects/public');
            }}
            arrow="horizontal"
          >
            <FormattedMessage id="profile.public.project" />
          </Item>
        </List>
      </div>
    );
  }
}
export default withRouter(Profile);
