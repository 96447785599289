import React, { Component } from 'react';
import moment from 'moment';

import { formatCountdown } from './utils';

const REFRESH_INTERVAL = 1000 / 30;

function getTime(value) {
  return moment(value).valueOf();
}

class CountDown extends Component {
  static defaultProps = {
    format: 'HH:mm:ss',
  };

  state = {
    startDatetime: null,
  };

  countdownId = 0;

  componentDidMount() {
    this.setState({
      startDatetime: moment(),
    });

    this.syncTimer();
  }

  componentWillReceiveProps(newProps) {
    const { serverDate } = this.props;
    const { serverDate: newServerDate } = newProps;

    if (newServerDate && serverDate.valueOf() !== newServerDate.valueOf()) {
      this.setState({
        startDatetime: moment(),
      });
    }
  }

  componentDidUpdate() {
    this.syncTimer();
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  syncTimer = () => {
    const { value, serverDate } = this.props;
    const { startDatetime } = this.state;

    const timestamp = getTime(value);
    if (
      timestamp >
      moment().valueOf() - moment(startDatetime).valueOf() + moment(serverDate).valueOf()
    ) {
      this.startTimer();
    } else {
      this.stopTimer();
    }
  };

  startTimer = () => {
    if (this.countdownId) return;

    this.countdownId = window.setInterval(() => {
      this.forceUpdate();
    }, REFRESH_INTERVAL);
  };

  stopTimer = () => {
    const { onFinish, value } = this.props;
    if (this.countdownId) {
      clearInterval(this.countdownId);
      this.countdownId = undefined;

      const timestamp = getTime(value);
      if (onFinish && timestamp < Date.now()) {
        onFinish();
      }
    }
  };

  formatCountdown = (value) => {
    const { serverDate, limitMinutes } = this.props;
    const { startDatetime } = this.state;
    return formatCountdown(value, startDatetime, serverDate, limitMinutes);
  };

  // Countdown do not need display the timestamp
  valueRender = (node) =>
    React.cloneElement(node, {
      title: undefined,
    });

  render() {
    return (
      <div style={this.props.valueStyle}>
        {this.formatCountdown(this.props.value)}
        {/* countdown... */}
      </div>
    );
  }
}

export default CountDown;
