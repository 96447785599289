import * as ACTION from '../constants';

const INITIAL_STATE = {
  error: null,
  loading: false,
  list: [],
  pagination: {},
};

export default function (state = INITIAL_STATE, action) {

  switch (action.type) {
    case ACTION.TICKETS_REQUEST:
      return {
        ...state,
        list: [],
        loading: true,
        error: null,
      };
    case ACTION.TICKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.data || [],
        pagination: {
          current: action.current,
          pageSize: action.pageSize,
          total: action.total,
        } || {},
        error: null,
      };
    case ACTION.TICKETS_FAILURE:
      return {
        ...state,
        list: [],
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
