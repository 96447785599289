import { Card, NavBar, Icon, } from 'antd-mobile';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  withRouter,
} from 'react-router-dom';

import { FILE_URL } from '../constants/';

import Profile from '../components/Profile';

const { Header } = Card;

class Main extends Component {
  render() {
    const {
      systemuser,
    } = this.props;
    return (
      <div>
        <NavBar
          mode="dark"
          icon={<Icon type="left" />}
          onLeftClick={() => { window.history.back() }}
        >Smart PA</NavBar>
        {
          systemuser &&
          <Card full>
            <Header
              title={systemuser.fullname && systemuser.fullname}
              thumb={systemuser.avatar && `${FILE_URL}/${systemuser.avatar}`}
              thumbStyle={{
                borderRadius: '50%',
                width: 88,
                height: 88,
                margin: 5,
              }}
            />
            <Profile profile={systemuser} />
          </Card>
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  systemuser: state.systemuser.data,
});
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Main));
