import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import no_data from '../assets/empty.svg';
class NoData extends Component {
  render () {
    const noDataStyle={
      textAlign: 'center',
    }
    return (
      <div style={noDataStyle}>
        <img alt="暂无数据" src={no_data} />
      </div>
    )
  }
}
export default withRouter(NoData);
