import React from 'react';
import moment from 'moment';

import { formatTimePeriod } from '../utils/utils';

import CountDown from './CountDown/index';

// 残り時間
const Deadline = ({
  suspend,
  deadlinePeriod,
  deadlineDate,
  serverDate,
  limitMinutes,
  wrap = false,
  intl,
}) => {

  if (suspend) {
    return deadlinePeriod < 0 ? (
      <div>
        { intl.formatMessage({ id: 'p/tkt/c/Deadline.process.timeout' }) }
        {wrap && <br />}
        <span style={{ color: 'red' }}>
          {formatTimePeriod(parseInt(deadlinePeriod / 1000, 10))}
        </span>
      </div>
    ) : (
      <div>{formatTimePeriod(parseInt(limitMinutes * 60 - deadlinePeriod / 1000, 10))}</div>
    );
  }

  const diff = moment(deadlineDate).diff(moment(serverDate));
  return diff > 0 ? (
    <CountDown
      valueStyle={{
        fontSize: 16,
        color: '#3f8600',
      }}
      value={moment(deadlineDate)}
      serverDate={moment(serverDate)}
      limitMinutes={limitMinutes}
    />
  ) : (
    <div>
      { intl.formatMessage({ id: 'p/tkt/c/Deadline.process.timeout' }) }
      {wrap && <br />}
      <span style={{ color: 'red' }}>{formatTimePeriod(parseInt(diff / 1000, 10))}</span>
    </div>
  );
};

export default Deadline;
