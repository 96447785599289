import { REMOTE_URL } from '../constants/';

import { CALL_API } from '../middleware/api';
import * as ACTION from '../constants';

export const touch = (profile) => ({
  [CALL_API]: {
    endpoint: `${REMOTE_URL}/api/line/touch`,
    init: {
      method: 'POST',
      body: JSON.stringify(profile),
    },
    types: [
      ACTION.SYSTEMUSER_TOUCH_REQUEST,
      ACTION.SYSTEMUSER_TOUCH_SUCCESS,
      ACTION.SYSTEMUSER_TOUCH_FAILURE,
    ],
  },
});

