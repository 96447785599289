import { stringify } from 'qs';
import { REMOTE_URL } from '../constants/';

import { CALL_API } from '../middleware/api';
import * as ACTION from '../constants';

export const getProjects = (action, params) => ({
  [CALL_API]: {
    endpoint: `${REMOTE_URL}/api/${action}?${stringify(params)}`,
    init: {
      method: 'GET',
    },
    types: [
      ACTION.PROJECTS_REQUEST,
      ACTION.PROJECTS_SUCCESS,
      ACTION.PROJECTS_FAILURE,
    ],
  },
});
