import { combineReducers } from 'redux';

import systemuser from './systemuser';
import projects from './projects';
import tickets from './tickets';
import ticket from './ticket';

const rootReducer = combineReducers({
  projects,
  systemuser,
  tickets,
  ticket,
});

export default rootReducer;
