import React, { useState } from 'react';
import { List, InputItem, TextareaItem, WhiteSpace } from 'antd-mobile';

const Mailer = ({ mail, onChanged, intl, activeStep }) => {
  const [mailInfo, setMailInfo] = useState(mail);

  return (
    <List className="mailContent">
      <InputItem
        placeholder="To"
        value={mailInfo.to}
        disabled={!activeStep}
        onChange={(v) => {
          setMailInfo({
            ...mailInfo,
            to: v,
          });
          onChanged({
            ...mailInfo,
            to: v,
          });
        }}
      >{ intl.formatMessage({ id: 'p/flow/c/Mailer.to' }) }</InputItem>
      <InputItem
          placeholder="Cc"
          value={mailInfo.cc}
          disabled={!activeStep}
          onChange={(v) => {
            setMailInfo({
              ...mailInfo,
              cc: v,
            });
            onChanged({
              ...mailInfo,
              cc: v,
            });
          }}
        >{ intl.formatMessage({ id: 'p/flow/c/Mailer.cc' }) }</InputItem>
      <InputItem
        placeholder="Bcc"
        value={mailInfo.bcc}
        disabled={!activeStep}
        onChange={(v) => {
          setMailInfo({
            ...mailInfo,
            bcc: v,
          });
          onChanged({
            ...mailInfo,
            bcc: v,
          });
        }}
      >Bcc</InputItem>
      <TextareaItem
        title={ intl.formatMessage({ id: 'p/flow/c/Mailer.attachments' }) }
        autoHeight
        placeholder="Attachments"
        value={mailInfo.attachments}
        disabled={!activeStep}
        onChange={(v) => {
          setMailInfo({
            ...mailInfo,
            attachments: v,
          });
          onChanged({
            ...mailInfo,
            attachments: v,
          });
        }}
      />
      <InputItem
        placeholder="Subject"
        value={mailInfo.subject}
        disabled={!activeStep}
        onChange={(v) => {
          setMailInfo({
            ...mailInfo,
            subject: v,
          });
          onChanged({
            ...mailInfo,
            subject: v,
          });
        }}
      >{ intl.formatMessage({ id: 'p/flow/c/Mailer.subject' }) }</InputItem>
    <TextareaItem
        title={ intl.formatMessage({ id: 'p/flow/c/Mailer.body' }) }
        placeholder="Body"
        rows={5}
        value={mailInfo.body}
        disabled={!activeStep}
        onChange={(v) => {
          setMailInfo({
            ...mailInfo,
            body: v,
          });
          onChanged({
            ...mailInfo,
            body: v,
          });
        }}
      />
    </List>
  );
};

export default ({ mail, onChanged, intl, activeStep }) => {
  const [data, setData] = useState(mail);

  return (
    <Mailer
      mail={mail}
      intl={intl}
      activeStep={activeStep}
      onChanged={(v) => {
        const newData = {
          ...data,
          ...v,
        };
        setData(newData);
        onChanged(newData);
      }}
    />
  );
};
