import moment from 'moment';
import padStart from 'lodash/padStart';

// Countdown
const timeUnits = [
  ['Y', 1000 * 60 * 60 * 24 * 365],
  ['M', 1000 * 60 * 60 * 24 * 30],
  ['D', 1000 * 60 * 60 * 24],
  ['H', 1000 * 60 * 60],
  ['m', 1000 * 60],
  ['s', 1000],
  ['S', 1],
];

function formatTimeStr(duration, format) {
  let leftDuration = duration;

  return timeUnits.reduce((current, [name, unit]) => {
    if (current.indexOf(name) !== -1) {
      const value = Math.floor(leftDuration / unit);
      leftDuration -= value * unit;
      return current.replace(new RegExp(`${name}+`, 'g'), (match) => {
        const len = match.length;
        return padStart(value.toString(), len, '0');
      });
    }
    return current;
  }, format);
}

const dynamicFormat = (diff) => {
  if (diff < 1000 * 60) {
    return 's秒';
  }
  if (diff < 1000 * 60 * 60) {
    return 'm分s秒';
  }
  if (diff < 1000 * 60 * 60 * 24) {
    return 'H時間m分s秒';
  }
  return 'D日H時間m分s秒';
};

export function formatCountdown(value, startDatetime, serverDate, limitMinutes) {
  const target = moment(value).valueOf();
  const currentVal =
    moment(serverDate).valueOf() + moment().valueOf() - moment(startDatetime).valueOf();
  const diff = limitMinutes * 60 * 1000 - Math.max(target - currentVal, 0);

  return formatTimeStr(diff, dynamicFormat(diff));
}
