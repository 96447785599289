import { List, Pagination, NavBar, Icon, } from 'antd-mobile';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  withRouter,
} from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import {
  getProjects,
} from '../actions/projects';

import NoData from '../components/NoData';

const { Item } = List;
let actions = {}
actions['my'] = 'work-project/owner';
actions['participated'] = 'work-project/my/participated';
actions['public'] = 'work-project/public';

let titles = {}
titles['my'] = 'profile.my.project';
titles['participated'] = 'profile.participated.project';
titles['public'] = 'profile.public.project';

class Projects extends Component {
  componentDidMount() {
    const {
      match: {
        params: { type }
      },
      systemuser,
    } = this.props;

    this.props.getProjects(type === 'my' ? `${actions[type]}/${systemuser.id}` : actions[type]);
  }

  onChangePage = page => {
    const {
      match: {
        params: { type }
      },
      systemuser,
    } = this.props;
    const params = {
      current: page,
    };
    this.props.getProjects(type === 'my' ? `${actions[type]}/${systemuser.id}` : actions[type], params);
  }

  render() {
    const {
      match: {
        params: { type }
      },
      projects,
      pagination,
    } = this.props;
    return (
      <div>
        <NavBar
          mode="dark"
          icon={<Icon type="left" />}
          onLeftClick={() => { window.history.back() }}
        ><FormattedMessage id={titles[type]} /></NavBar>
        <List
          renderHeader={() => <FormattedMessage id={titles[type]} />}
        >
          {projects.length > 0 ? projects.map(item => (
            <Item
              key={item.id}
              onClick={() => {
                !item.delete_flag && this.props.history.push(`/tickets/${item.id}`);
              }}
              arrow={item.delete_flag ? "empty" : "horizontal"}
            >
              <span style={{ textDecoration: item.delete_flag ? 'line-through' : 'unset', }}>
                {item.owner.userid}/{item.projectid}
              </span>
            </Item>
          )) : (
              <NoData />
            )
          }
        </List>
        {
          projects.length > 0 && (
            <Pagination total={Math.ceil(pagination.total / pagination.pageSize)} current={pagination.current} locale={{ prevText: <FormattedMessage id="prev" />, nextText: <FormattedMessage id="next" /> }} onChange={this.onChangePage} />
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  systemuser: state.systemuser.data,
  projects: state.projects.list,
  pagination: state.projects.pagination,
});
const mapDispatchToProps = dispatch => ({
  getProjects: (action, params) => dispatch(getProjects(action, params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Projects));
