import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import StepDetail from '../components/StepDetail';
import Deadline from '../components/Deadline';
import { formatTimePeriod } from '../utils/utils';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  ActionSheet,
  List,
  Toast,
  WingBlank,
  TextareaItem,
  Flex,
  Button,
  WhiteSpace,
  ActivityIndicator,
  Accordion,
  Card,
  NavBar,
  Icon,
  Popover,
  Steps,
  Tag,
  Radio,
} from 'antd-mobile';

import {
  getTicket, ticketAction, executeVm,
} from '../actions/ticket';

const Item = List.Item;
const Brief = Item.Brief;
const Step = Steps.Step;
const PopoverItem = Popover.Item;

const TicketDetailItem = ({ title, value }) => (
  <Flex justify="between" style={{ padding: '6px 0px' }}>
    <div style={{ fontWeight: 'bold' }}>{title}</div>
    <div>{value}</div>
  </Flex>
);

class Ticket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputMode: false,
      body: '',
      rightMenuVisible: false,
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { project, id }
      },
    } = this.props;

    this.props.getTicket({
      project,
      id,
    });
  }

  cancelTicket = (memo) => {
    const { detail, ticketAction, match, intl } = this.props;
    const test_flag = this.props.location.query?.test_flag === 'true';

    sessionStorage.removeItem(`resumeNode_${detail.id}`);
    ticketAction({
      type: 'ticket/action',
      action: 'cancel',
      project: match.params.project,
      id: detail.id,
      test_flag,
      payload: {
        memo,
        updatedAt: detail.updatedAt,
      },
      msg: intl.formatMessage({ id: 'p/tkt/Detail.cancelled' }),
    });
  };

  reopenTicket = () => {
    const { detail, ticketAction, match, intl } = this.props;
    const test_flag = this.props.location.query?.test_flag === 'true';

    sessionStorage.removeItem(`resumeNode_${detail.id}`);
    ticketAction({
      type: 'ticket/action',
      action: 'reopen',
      project: match.params.project,
      id: detail.id,
      test_flag,
      payload: {
        updatedAt: detail.updatedAt,
      },
      msg: intl.formatMessage({ id: 'p/tkt/Detail.restored' }),
    });
  };

  viewVmLogger = () => {
    // window.location = `${REMOTE_URL}/api/ticket/vmlog/${match.params.project}/${
    //   detail.id
    // }?authorization=Bearer ${getBearer()}`;
  };

  suspendTicket = () => {
    const { detail, ticketAction, match, intl } = this.props;
    const test_flag = this.props.location.query?.test_flag === 'true';
    ticketAction({
      type: 'ticket/action',
      action: `${detail.suspend ? 'resume' : 'suspend'}`,
      project: match.params.project,
      id: detail.id,
      test_flag,
      payload: {
        node_id: detail.nextStep.node_id,
        step_start_date: detail.nextStep.start_date,
        updatedAt: detail.updatedAt,
      },
      msg: detail.suspend ? intl.formatMessage({ id: 'Detail.reopen.succeed' }) : intl.formatMessage({ id: 'p/tkt/Detail.suspend.succeed' }),
    });
  };

  showCancelTicketMenu = () => {
    const { intl } = this.props;
    const BUTTONS = [
      intl.formatMessage({ id: 'p/tkt/Detail.wrong.ticket' }),
      intl.formatMessage({ id: 'p/tkt/Detail.auto.fixed' }),
      intl.formatMessage({ id: 'p/tkt/Detail.etc.reason' }),
      intl.formatMessage({ id: 'p/tkt/Detail.cancel' }),
    ];
    const BUTTONS_KEY = ['wrong ticket', 'auto fixed', 'etc reason'];
    ActionSheet.showActionSheetWithOptions({
      options: BUTTONS,
      cancelButtonIndex: BUTTONS.length - 1,
      maskClosable: true,
      'data-seed': 'logId',
    },
      (buttonIndex) => {
        if (buttonIndex < BUTTONS_KEY.length) {
          this.cancelTicket(BUTTONS_KEY[buttonIndex]);
        }
      });
  }

  ticketActionItems = ({
    projectPerms,
    ticket,
  }) => {
    const { intl } = this.props;
    return [
      ticket.status === 'started' && ticket.nextStep && projectPerms.some((r) => ['project.*', 'flow.*', 'ticket.*'].includes(r)) && (
        <PopoverItem key="suspend" value={ticket.suspend ? 'reopen' : 'suspend'} disabled={ticket.delete_flag}>
          {ticket.suspend ? intl.formatMessage({ id: 'p/tkt/Detail.reopen' }) : intl.formatMessage({ id: 'p/tkt/Detail.suspend' }) }
        </PopoverItem>
      ),
      ticket.status === 'started' && ticket.nextStep && projectPerms.some((r) => ['project.*', 'flow.*', 'ticket.*'].includes(r)) && (
        <PopoverItem key="cancel" value="cancel" disabled={ticket.delete_flag}>
          { intl.formatMessage({ id: 'p/tkt/Detail.cancel' }) }
        </PopoverItem>
      ),
      ticket.status === 'closed' && projectPerms.some((r) => ['project.*', 'flow.*', 'ticket.*'].includes(r)) && (
        <PopoverItem key="restore" value="restore" disabled={ticket.delete_flag}>
          { intl.formatMessage({ id: 'p/tkt/Detail.restore' }) }
        </PopoverItem>
      ),
      projectPerms.some((r) => ['project.*', 'flow.*', 'ticket.*'].includes(r)) && (
        <PopoverItem key="vmLog" value="vmLog">
          { intl.formatMessage({ id: 'p/tkt/Detail.viewlog' }) }
        </PopoverItem>
      ),
    ];
  };

  onSelect = (opt) => {
    const { value } = opt.props;

    this.setState({
      rightMenuVisible: false,
    });

    switch (value) {
      case 'reopen':
      case 'suspend':
        this.suspendTicket();
        break;
      case 'cancel':
        this.showCancelTicketMenu();
        break;
      case 'restore':
        this.reopenTicket();
        break;
      case 'vmLog':
        this.viewVmLogger();
        break;
      default:
        break;
    }

  };
  handleVisibleChange = (rightMenuVisible) => {
    this.setState({
      rightMenuVisible,
    });
  };

  statusName = (key) => {
    const { intl } = this.props;
    let name = '';
    switch (key) {
      case 'initial':
        name = intl.formatMessage({ id: 'initial' });
        break;
      case 'suspend':
        name = intl.formatMessage({ id: 'suspend' });
        break;
      case 'started':
        name = intl.formatMessage({ id: 'started' });
        break;
      case 'closed':
        name = intl.formatMessage({ id: 'closed' });
        break;
      case 'canceled':
        name = intl.formatMessage({ id: 'canceled' });
        break;
      default:
        break;
    }
    return name;
  }

  operatingTime = (detail) => {
    const { intl } = this.props;
    return (detail.status === 'started' && detail.deadlinePeriod ? (
      <TicketDetailItem title={intl.formatMessage({ id: 'p/tkt/Detail.time.passed' })} value={
        <Deadline
          suspend={detail.suspend}
          deadlinePeriod={detail.deadlinePeriod}
          deadlineDate={detail.deadlineDate}
          serverDate={detail.serverDate}
          limitMinutes={detail.limitMinutes}
          wrap
          intl={intl}
        />
      } />
    ) : (
        <TicketDetailItem title={intl.formatMessage({ id: 'p/tkt/Detail.work.time' })} value={formatTimePeriod(parseInt((detail.millisUsed || 0) / 1000, 10))} />
      ));
  }

  render() {
    const { inputMode, body } = this.state;
    const { ticketAction, intl } = this.props;
    const { detail, projectPerms, loading } = this.props;
    const { steps = [], nextStep } = detail;

    console.log(this.props);
    const test_flag = this.props.location.query?.test_flag === 'true';

    return (
      <div>
        {
          (loading) && <ActivityIndicator toast size="large" text="loading" />
        }

        <NavBar
          mode="dark"
          icon={<Icon type="left" />}
          onLeftClick={() => { window.history.back() }}
          rightContent={
            projectPerms.some((r) => ['project.*', 'flow.*', 'ticket.*'].includes(r)) &&
            <Popover mask
              overlayClassName="fortest"
              overlayStyle={{ color: 'currentColor' }}
              visible={this.state.rightMenuVisible}
              overlay={this.ticketActionItems({ ticket: detail, projectPerms })}
              align={{
                overflow: { adjustY: 0, adjustX: 0 },
                offset: [-10, 0],
              }}
              onVisibleChange={this.handleVisibleChange}
              onSelect={this.onSelect}
            >
              <div style={{
                height: '100%',
                padding: '0 15px',
                marginRight: '-15px',
                display: 'flex',
                alignItems: 'center',
              }}
              >
                <Icon type="ellipsis" />
              </div>
            </Popover>
          }
        >Ticket</NavBar>
        <Accordion defaultActiveKey="0" className="my-accordion" accordion>
          <Accordion.Panel header={<><Tag>{this.statusName(detail.statusName)}</Tag> {(detail.id && (detail.ticket_name))}</>}>
            <Card full>
              <Card.Body style={{ fontSize: 13 }}>
                {this.operatingTime(detail)}
                <TicketDetailItem title={intl.formatMessage({ id: 'p/usr/Detail.project' })} value={detail.workProject && (detail.workProject.projectid)} />
                <TicketDetailItem title={intl.formatMessage({ id: 'p/tkt/Detail.flow' })} value={detail.flow_snapshot && detail.flow_snapshot.flow_name} />
                <TicketDetailItem title={intl.formatMessage({ id: 'p/tkt/Detail.creator' })} value={detail.creator && detail.creator.fullname} />
                <TicketDetailItem title={intl.formatMessage({ id: 'p/tkt/Detail.createat' })} value={moment(detail.createdAt).format('YYYY-MM-DD HH:mm:ss')} />
              </Card.Body>
            </Card>
          </Accordion.Panel>
        </Accordion>

        <br />
        <WingBlank size="sm">
          <Accordion defaultActiveKey="0" className="my-accordion" defaultActiveKey="step-current-node">
            {steps.map((s) => (
              <Accordion.Panel header={s.node.label} key={s._id}>
                {s.node && <StepDetail ticket={detail} projectPerms={projectPerms} node={s.node} step={s} test_flag={test_flag} intl={intl} />}
              </Accordion.Panel>
            ))}
            {detail.status === 'started' && nextStep && (
              <Accordion.Panel header={nextStep.node.label} key="step-current-node">
                <StepDetail
                  key={`n_${nextStep.node.id}`}
                  ticket={detail}
                  projectPerms={projectPerms}
                  node={nextStep.node}
                  step={nextStep}
                  activeStep
                  test_flag={test_flag}
                  ticketAction={ticketAction}
                  executeVm={executeVm}
                  intl={intl}
                />
              </Accordion.Panel>
            )}
          </Accordion>
        </WingBlank>
        <br />

      </div>
    );
  }
}

const mapStateToProps = state => ({
  detail: state.ticket.data,
  projectPerms: state.ticket.permissions,
  loading: state.ticket.loading,
});
const mapDispatchToProps = dispatch => ({
  getTicket: (id) => dispatch(getTicket(id)),
  executeVm: (params) => dispatch(executeVm(params)),
  ticketAction: (params) => dispatch(ticketAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(Ticket)));
