export default {
  'prev': '前へ',
  'next': '次へ',
  'profile.info': 'プロフィール情報',
  'profile.name': '名前',
  'profile.email': 'メール',
  'profile.mobileno': '携帯番号',
  'profile.created': '作成日',
  'profile.project': 'プロジェクト',
  'profile.my.project': '私のプロジェクト',
  'profile.participated.project': '関連プロジェクト',
  'profile.public.project': '公開プロジェクト',
  'ticket.list': 'チケットリスト',
  'p/tkt/CommentDetail.saved': 'コメントを保存しました。',
  'p/tkt/Detail.back': '戻る',
  'p/tkt/Detail.cancelled': 'キャンセルしました。',
  'p/tkt/Detail.createat': '作成時刻',
  'p/tkt/Detail.creator': '作成者',
  'p/tkt/Detail.end.date': '終了時刻',
  'p/tkt/Detail.flow': 'フロー',
  'p/tkt/Detail.flow.chart': 'フロー図',
  'p/tkt/Detail.process.time': '目標時間',
  'p/tkt/Detail.project': 'プロジェクト',
  'p/tkt/Detail.reopen': '再開',
  'p/tkt/Detail.reopen.succeed': '再開しました。',
  'p/tkt/Detail.restore': '復活',
  'p/tkt/Detail.restored': '復活しました。',
  'p/tkt/Detail.same.flow': '類似チケット',
  'p/tkt/Detail.status': 'ステータス',
  'p/tkt/Detail.suspend': '一時停止',
  'p/tkt/Detail.suspend.succeed': '一時停止しました。',
  'p/tkt/Detail.test.only': 'フローの確認用です。本番チケットではありません。',
  'p/tkt/Detail.ticket.detail': '作業詳細',
  'p/tkt/Detail.time.passed': '経過時間',
  'p/tkt/Detail.viewlog': '作業ログ',
  'p/tkt/Detail.warning': '警告あり',
  'p/tkt/Detail.work.time': '作業時間',
  'p/tkt/StepDetail.end.time': '完了時刻',
  'p/tkt/StepDetail.mailer': 'メーラー',
  'p/tkt/StepDetail.memo': 'メモ',
  'p/tkt/StepDetail.start.time': '開始時刻',
  'p/tkt/StepDetail.suspend': '一時停止',
  'p/tkt/StepDetail.time.limited': '目標時間',
  'p/tkt/StepDetail.time.used': '経過時間',
  'p/tkt/StepDetail.work.action.label': 'アクション',
  'p/tkt/StepDetail.work.content': '作業内容',
  'p/tkt/c/CommentDetail.add.comment': 'コメント追加',
  'p/tkt/c/CommentDetail.worklog': 'コメント',
  'p/tkt/c/Deadline.process.timeout': '目標時間超過',
  'p/tkt/c/StepDetail.close': '閉じる',
  'p/tkt/c/StepDetail.executing': '実行中。。。',
  'p/tkt/c/StepDetail.next': '次へ',
  'p/tkt/c/StepDetail.node.resume': 'ここから再開',
  'p/tkt/c/StepDetail.operator': '操作者:',
  'p/tkt/c/StepDetail.send': '送信',
  'p/tkt/c/StepDetail.unexpeted.error': '予想外エラー',
  'p/tkt/c/TicketHeader.none': 'なし',
  'p/user/login/index.countdown': '{count}秒',
  'p/user/login/index.get.code': 'コードを取得',
  'p/user/login/index.login': '登录',
  'p/usr/Detail.activity': 'アクティビティ',
  'p/usr/Detail.edit': '編集...',
  'p/usr/Detail.flow': 'フロー',
  'p/usr/Detail.overview': '概要',
  'p/usr/Detail.project': 'プロジェクト',
  'p/usr/Detail.relatived.group': '所属グループ',
  'p/usr/Detail.snippet': 'スニペット',
  'p/usr/Detail.ticket': 'チケット',
  'p/usr/List.new': '新規',
  'p/usr/c/Overview/index.acl_role': 'ロール',
  'p/usr/c/Overview/index.createdat': '作成日',
  'p/usr/c/Overview/index.fullname': '名前',
  'p/usr/c/Overview/index.id': 'ID',
  'p/usr/c/Overview/index.mail': 'メール',
  'p/usr/c/Overview/index.memo': 'メモ',
  'p/usr/c/Overview/index.mobileno': '携帯番号',
  'p/tkt/Detail.auto.fixed': '自動解決',
  'p/tkt/Detail.cancel': '取消',
  'p/tkt/Detail.etc.reason': 'その他の原因',
  'p/tkt/Detail.wrong.ticket': '起票間違い',
  'initial': '対応中',
  'suspend': '一時停止',
  'started': '対応中',
  'closed': '正常終了',
  'canceled': 'キャンセル',
  'p/flow/c/Mailer.attachments': '添付',
  'p/flow/c/Mailer.bcc': 'BCC',
  'p/flow/c/Mailer.body': '本文',
  'p/flow/c/Mailer.cc': 'CC',
  'p/flow/c/Mailer.subject': '件名',
  'p/flow/c/Mailer.to': '宛先',
  'p/flow/NodeDetailForm.input': '入力項目',
  'form.save': '保存',
};
