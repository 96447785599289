import { stringify } from 'qs';
import { REMOTE_URL } from '../constants/';

import { CALL_API } from '../middleware/api';
import * as ACTION from '../constants';

export const getTickets = (projectid, params) => ({
  [CALL_API]: {
    endpoint: `${REMOTE_URL}/api/ticket/${projectid}?${stringify(params)}`,
    init: {
      method: 'GET',
    },
    types: [
      ACTION.TICKETS_REQUEST,
      ACTION.TICKETS_SUCCESS,
      ACTION.TICKETS_FAILURE,
    ],
  },
});

export const getTicket = ({ project, id, params, test_flag = false }) => {
  const resumeNode = sessionStorage.getItem(`resumeNode_${id}`);
  const query = resumeNode
    ? {
        ...params,
        resumeNode,
        test_flag,
      }
    : {
        ...params,
        test_flag,
      };
  return {
    [CALL_API]: {
      // endpoint: `${REMOTE_URL}/api/line/interview/${id}`,
      endpoint: `${REMOTE_URL}/api/ticket/${project}/${id}?${stringify(query)}`,
      init: {
        method: 'GET',
      },
      token: 'JDJhJDEwJDh1bUhVVXBXdTlmMGxxMVp5L1JqRy5RaGNyRmZCaG55dUdsdVV4Z2QyMlNhcmVPa0FOTXJH',
      types: [
        ACTION.TICKET_REQUEST,
        ACTION.TICKET_SUCCESS,
        ACTION.TICKET_FAILURE,
      ],
    },
  }
};

export const ticketAction = ({ project, id, action, payload, msg, callback, test_flag = false }) => {
  if (action !== 'resumeStep') {
    sessionStorage.removeItem(`resumeNode_${id}`);
  }

  const resumeNode = sessionStorage.getItem(`resumeNode_${id}`);
  const query = resumeNode
    ? {
        resumeNode,
        test_flag,
      }
    : {
        test_flag,
      };

  return {
    [CALL_API]: {
      // endpoint: `${REMOTE_URL}/api/line/interview/${id}`,
      endpoint: `${REMOTE_URL}/api/ticket/${project}/${id}/${action}?${stringify(query)}`,
      init: {
        method: 'POST',
        body: JSON.stringify(payload),
      },
      token: 'JDJhJDEwJDh1bUhVVXBXdTlmMGxxMVp5L1JqRy5RaGNyRmZCaG55dUdsdVV4Z2QyMlNhcmVPa0FOTXJH',
      types: [
        ACTION.TICKET_REQUEST,
        ACTION.TICKET_SUCCESS,
        ACTION.TICKET_FAILURE,
      ],
    },
  }
};

export const executeVm = ({ project, ticketId, node_id, data, test_flag = false }) => {
  const query = {
    test_flag,
  };
  return {
    [CALL_API]: {
      // endpoint: `${REMOTE_URL}/api/line/interview/${id}`,
      endpoint: `${REMOTE_URL}/api/ticket/vm/${project}/${ticketId}/${node_id}/exec?${stringify(query)}`,
      init: {
        method: 'POST',
        body: {
          ...data,
        }
      },
      token: 'JDJhJDEwJDh1bUhVVXBXdTlmMGxxMVp5L1JqRy5RaGNyRmZCaG55dUdsdVV4Z2QyMlNhcmVPa0FOTXJH',
      types: [
        ACTION.TICKET_VM_REQUEST,
        ACTION.TICKET_VM_SUCCESS,
        ACTION.TICKET_VM_FAILURE,
      ],
    },
  }
};
