import liff from '@line/liff';

let isInit = false;
let profile = {};

class liffHelper {
  init() {
    return new Promise((resolve, reject) => {
      if (!isInit) {
        liff.init({
          liffId: '1655593851-8a1K2l3b',
        })
          .then(() => {
            isInit = true;
            if (!liff.isLoggedIn()) {
              liff.login();
            }
            resolve();
          })
          .catch((err) => {
            alert(err);
            reject();
          });
      } else {
        resolve();
      }
    });
  }

  getLIFFInfo() {
    return liff;
  }

  getProfile() {
    return new Promise((resolve, reject) => {
      if (process.env.NODE_ENV === 'development') {
        resolve({
          "userId":"58b1418f12bb5407c5cc9ae1cec979cb",
          "displayName":"test user",
          "pictureUrl":'https://obs.line-scdn.net/0hOYzHkbsaEHZHMTvWpKBvIXt0HhswHxY-P14LE2Q0RhY_BFcgeQUPQGBkR0U-A1UjKAJWQzFlTxU6/preview',
          "statusMessage":"Hello, LINE!"
        });
        return;
      }
      this.init()
        .then(() => {
          if (isInit && !profile.userId) {
            liff.getProfile()
              .then(pf => {
                profile = pf;
                resolve(profile);
              })
              .catch((err) => {
                console.log('get profile error', err);
                reject(err);
              });
          } else {
            resolve(profile)
          }
        })
        .catch(err => { reject(err) });
    });
  }
};
export default new liffHelper();
