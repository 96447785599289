import qs from 'qs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";

import {
  Toast,
  ActivityIndicator,
} from 'antd-mobile';

import './App.css';

import Main from './containers/Main';
import Projects from './containers/Projects';
import Tickets from './containers/Tickets';
import Ticket from './containers/Ticket';
import liffHelper from './utils/liffHelper';

import {
  touch,
} from './actions/systemuser';
import * as ACTION from './constants';

class App extends Component {
  constructor(props) {
    super(props);
    liffHelper.getProfile().then(profile => {
      this.touchSystemuser({
        ...profile,
        lineid: profile.userId,
        avatar: profile.pictureUrl,
      });
    }).catch((err) => {
      Toast.fail(err);
    });
  }

  componentDidMount() {
    window.emitter.addListener(ACTION.SYSTEMUSER_TOUCH_SUCCESS, ({ bearer }) => {
      localStorage.setItem('bearer', bearer);
    });
    window.emitter.addListener(ACTION.SYSTEMUSER_TOUCH_FAILURE, ({ error }) => {
      Toast.fail(error);
    });
  }

  touchSystemuser = data => {
    const { systemuser } = this.props;
    systemuser.id || this.props.touch(data);
  };

  render() {
    const {
      systemuser,
      error,
    } = this.props;

    return (
      <div className="app">
        {
          (systemuser.id) ? (
            <Router>
              <Switch>
                <Route exact path="/" component={Main} />
                <Route exact path="/projects/:type" component={Projects} />
                <Route exact path="/tickets/:projectid" component={Tickets} />
                <Route path="/ticket/:project/:id" component={Ticket} />
              </Switch>
            </Router>
          ) : (
              !error && < ActivityIndicator toast size="large" text="loading" />
            )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  systemuser: state.systemuser.data,
  error: state.systemuser.error,
});
const mapDispatchToProps = dispatch => ({
  touch: (profile) => dispatch(touch(profile)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
